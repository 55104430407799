import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from 'react-router-dom';

import UsersList from '../components/UsersList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import ParticlesBg from 'particles-bg';
import MouseParticles from 'react-mouse-particles';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CopyrightIcon from '@material-ui/icons/Copyright';
import CodeIcon from '@material-ui/icons/Code';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import './Users.css';

const Users = () => {
  const { isLoading, error,setIsLoading,sendRequest, clearError } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();
  const [page, setPage] = useState(1);
  const [searched, setSearched] = useState(false);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL+'/users'
        );
        setLoadedUsers(responseData.users);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest,page]);

  const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiPaginationItem-root': {
        marginTop: theme.spacing(1),
        color: 'white',
      },
      '& .MuiPaginationItem-outlined':{
        border: "1px solid #4D9616",
        backgroundColor: "rgb(48,50,51,0.4)"
      },
      '& .Mui-selected': {
        backgroundColor: "rgb(48,50,51)"
      },
      '& .MuiPaginationItem-root:hover': {
        backgroundColor: "rgb(48,50,51,0.8)"
      },

      '& .MuiTextField-root': {
        color: '#4D9616',
        backgroundColor: "rgb(48,50,51,0.7)",
        borderRadius: '25px',
        width: '17.5rem'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '25px',
      },
      '& .MuiInputLabel-root': {
        color: "white"
      },
      '& 	.MuiAutocomplete-inputRoot': {
        color: 'white',
      }
    }
  }));

  const classes = useStyles();

  function onPageChange(e,page){
    setIsLoading(true);
    setPage(page)
    setIsLoading(false);
  }

  const [val, setVal] = useState();

  function onChange(e,value,_){
    setIsLoading(true);
    setSearched(true);
    if(value.name){
      setVal([value])
    } 
    setIsLoading(false);
    console.log(value)
  }
  function onInputChange(e,value,reason){
    setIsLoading(true);
    if(reason==='input'){
      setSearched(false);
      setVal([])
    } else {
      setSearched(true);
    }
    setIsLoading(false);
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && !auth.isLoggedIn && loadedUsers && 
        <div className='footeruser'> 
          <ErrorOutlineIcon className='maticon'/>
          <NavLink to="/auth" className="signlink">Sign In</NavLink> to Add Places!
        </div>}
      {!isLoading && loadedUsers && <div>
          <MouseParticles g={1} num={2} color="random" cull="stats,image-wrapper" level={6} />
          <ParticlesBg num={30} type="thick" bg={true} />
          <div className={classes.root}>
            <Autocomplete
                className='search'
                freeSolo
                id="free-solo-demo"
                disableClearable
                options={loadedUsers}
                getOptionLabel={(option)=>option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Users"
                    margin="normal"
                    variant="outlined"
                    InputProps={{ ...params.InputProps, type: 'search'}}
                  />
                )}
                onChange={(e,value,_) => onChange(e,value,_)}
                onInputChange={(e,value,reason) => onInputChange(e,value,reason)}
              />
          </div>
          {!searched && <UsersList items={loadedUsers.slice((page-1)*5,page*5)}/>}
          {val && val.length>0 && searched && <UsersList items={val}/>}
          <div className={classes.root}>
            {console.log(loadedUsers.length%5===0)}
            {!searched && <Pagination className="paginate" variant="outlined" count={loadedUsers.length%5===0 ? Math.floor(loadedUsers.length/5):Math.floor((loadedUsers.length/5)+1)}page={page} onChange={(e,page) => onPageChange(e,page)}/>}
          </div>
        </div>}   
      {!isLoading && loadedUsers && 
        <div className='footeruser'> 
          <CopyrightIcon className='maticon'/>
          Copyright 2021, SpeedyPlaces
          <CodeIcon className='maticon'/>
          Developed By Kush Rabadia
        </div>}

    </React.Fragment>
  );
};

export default Users;
