import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import PlaceList from '../components/PlaceList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import ParticlesBg from 'particles-bg';
import MouseParticles from 'react-mouse-particles';

import CopyrightIcon from '@material-ui/icons/Copyright';
import CodeIcon from '@material-ui/icons/Code';
import './Userplaces.css';

const UserPlaces = () => {
  const [loadedPlaces, setLoadedPlaces] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const userId = useParams().userId;

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL+`/places/user/${userId}`
        );
        setLoadedPlaces(responseData.places);
      } catch (err) {}
    };
    fetchPlaces();
  }, [sendRequest, userId]);

  const placeDeletedHandler = deletedPlaceId => {
    setLoadedPlaces(prevPlaces =>
      prevPlaces.filter(place => place.id !== deletedPlaceId)
    );
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedPlaces &&
        <div>
          <MouseParticles g={1} num={2} color={["white","#4D9616"]} cull="stats,image-wrapper" level={6} />
          <ParticlesBg num={70} type="tadpole" bg={true} />
          <PlaceList items={loadedPlaces} onDeletePlace={placeDeletedHandler} />
        </div>
      }
      {!isLoading && loadedPlaces && 
        <div className='footer'> 
          <CopyrightIcon className='maticon'/>
          Copyright 2021, SpeedyPlaces
          <CodeIcon className='maticon'/>
          Developed By Kush Rabadia
        </div>}
    </React.Fragment>
  );
};

export default UserPlaces;
